.loginWrapper {
    min-height:100vh;
    margin:-154px 0 -360px 0;
    padding:154px 0 360px 0;
    .flex-center;
    .login {
        width:400px;
        h2 {
            text-align:center;
            .text-format(@circular, 24px, 32px, darken(@blue, 30%), 400);
        }
        button {
            display:block;
            margin:20px auto 0;
            padding:0 30px;
            width:auto;
            min-width:190px;
        }
        > p {
            padding:0 20px;
            .text-format(@circular, 16px, 24px, #777, 400);
        }
        p {
            text-align:center;
            a {
                color:@blue;
            }
        }
        .cta {
            .display-flex;
            .align-items(center);
            .justify-content(space-between);
            .form-group {
                margin:0;
            }
            label {
                .display-flex;
                .align-items(center);
            }
            a,
            span {
                .text-format(@circular, 16px, 20px, @black, 400);
            }
            a {
                color:@blue;
            }
        }
        .error-message {
            text-align: center;
            min-height: 20px;
            span {
                display: inline-block;
                padding: 1px 5px;
                font-size: 13px;
                color: white;
                background-color: #f26d7d;
                -webkit-border-top-left-radius: 4px;
                -webkit-border-top-right-radius: 4px;
                -moz-border-radius-topleft: 4px;
                -moz-border-radius-topright: 4px;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
            }
        }
        .input-error {
            border-color: #f26d7d !important;
        }
    }
}

@media (max-width: @screen-md-max) {
	
}

@media (max-width: @screen-sm-max) {
	
}

@media (max-width: @screen-xs-max) {
	.loginWrapper {
		.login {
			padding:20px;
			width:auto;
		}
	}
}
