.btn {
	display: inline-block;
	margin-bottom: 0;
	font-weight: normal;
	text-align: center;
	vertical-align: middle;
	touch-action: manipulation;
	cursor: pointer;
	background-image: none;
	border: 1px solid transparent;
	white-space: nowrap;
	width:235px;
	height:70px;
	font-size:22px;
	letter-spacing:1px;
	font-family:@circular;
	.border-radius(3px);
	.user-select(none);
	.transition(all .3s ease);
	&,
	&:active,
	&.active {
		&:focus,
		&.focus {
			.tab-focus();
			outline:none;
		}
	}
	
	&:hover,
	&:focus,
	&.focus {
		color: #333;
		text-decoration: none;
		outline:none;
	}
	
	&:active,
	&.active {
		outline: 0;
		background-image: none;
		.box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
	}
	
	&.disabled,
	&[disabled] {
		cursor: not-allowed;
		pointer-events: none;
		.opacity(.65);
		.box-shadow(none);
	}
}

.btn-default {
	.button-variant(@white, transparent, @white);
}
.btn-blue {
	.button-variant(@white, @blue, @blue);
}
.btn-white {
	.button-variant(@blue, @white, @white);
}
.btn-outline {
    border-width:1px;
    background:0 0;
    &.btn-blue { color:@blue; }
    &.btn-white { color:@white; }
    &:hover {
        color:@white;
    }
}
.btn-auto {
    width:auto;
    padding:0 50px;
}

.btn-link {
	color: @blue;
	font-weight: normal;
	border-radius: 0;
	
	&,
	&:active,
	&.active,
	&[disabled],
	fieldset[disabled] & {
		background-color: transparent;
		.box-shadow(none);
	}
	&,
	&:hover,
	&:focus,
	&:active {
		border-color: transparent;
	}
	&:hover,
	&:focus {
		color: darken(@blue, 50%);
		text-decoration: underline;
		background-color: transparent;
	}
	&[disabled],
	fieldset[disabled] & {
		&:hover,
		&:focus {
			color: #333;
			text-decoration: none;
		}
	}
}

.btn-block {
	display: block;
	width: 100%;
}

.btn-block + .btn-block {
	margin-top: 5px;
}

input[type="submit"],
input[type="reset"],
input[type="button"] {
	&.btn-block {
		width: 100%;
	}
}
