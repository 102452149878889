body {
    max-width: 100vw;
}

.brand {
    color:@white;
    padding:0 0 100px;
    background-color:@blue;
    h1 {
        text-align:center;
        margin:30px 0;
        .text-format(@circular, 60px, 70px, @white, 600);
    }
    p {
        margin:0 auto;
        padding:0;
        text-align:center;
        .text-format(@circular, 30px, 42px, @white, 400);
    }
    .btn-white {
    	display:block;
    	margin:0 auto;
    }
    
    &.header2 {
        position:relative;
        padding:134px 0;
	    margin:-154px 0 0;
	    min-height:600px;
	    h1 {
	        margin:50px 0 20px;
	        padding: 0;
	        letter-spacing:-1px;
	        height:80px;
	        overflow:hidden;
	        .flex-center;
	        b {
	            font-weight:600;
	            display:block;
	            margin:0 7px;
	            .translate3d(0, 100%, 0);
                .animation(fadein 1s ease 1s forwards);
                &:nth-child(1) { .animation-delay(1.1s); }
                &:nth-child(2) { .animation-delay(1.2s); }
                &:nth-child(3) { .animation-delay(1.3s); }
                &:nth-child(4) { .animation-delay(1.4s); }
                &:nth-child(5) { .animation-delay(1.5s); }
	        }
	    }
	    p {
	        margin:0 auto 50px;
	        max-width: 1100px;
            padding: 0 30px;
            letter-spacing: -.5px;
            height:40px;
	        overflow:hidden;
	        b {
	            font-weight:normal;
	            display:block;
	            .translate3d(0, 100%, 0);
                .animation(fadein 1s ease 2s forwards);
	        }
	    }
	    .btn-white {
	        margin:0 auto 30px;
	        min-height:70px;

	        .flex-center;
	        &.green {
	            background-color:#32DAC4;
	            border:#32DAC4;
	            color:#fff;
	            &:hover {
	                background-color:fadeout(#32DAC4, 20%);
	            }
	        }
	    }
	    .partners {
	        padding:10px 50px;
	        pointer-events:none;
	        opacity:.8;
	        .position(absolute, auto, 0, 0, 0, 1);
	        .display-flex;
	        .align-items(center);
	        .justify-content(space-between);
            i { font-size:3vw; }
            .icon-winc { font-size:2.5vw; }
            .icon-thechive { font-size:2.5vw; }
        }
        &.noPartners {
            padding: 134px 0 0;
            min-height: 0;
            p {
                height:auto;
                padding:0 100px 50px;
                margin:0 auto;
            }
        }
	}

	.randomIcons {
	    position:absolute;
	    pointer-events:none;
	    bottom:2.5vw;
	    left:0;
	    right:0;
	    top:0;
	    i {
	        position:absolute;
	        font-size:64px;
	        opacity:.3;
	        .transform-origin(center);
	    }
        .icon-bicycle {
            bottom:6%;
            left:0%;
            font-size:72px;
            .transform(rotate(20deg));
        }
        .icon-briefcase {
            bottom:20%;
            left:7%;
            font-size:52px;
            .transform(rotate(-20deg));
        }
        .icon-bullhorn {
            bottom:3%;
            left:24%;
            font-size:54px;
            .transform(rotate(-20deg));
        }
        .icon-pencil {
            bottom:7%;
            left:15%;
            font-size:52px;
            .transform(rotate(20deg));
        }
        .icon-cloud {
            bottom:35%;
            left:1%;
            font-size:48px;
            .transform(rotate(-20deg));
        }
        .icon-coffee-cup {
            bottom:6%;
            right:0%;
            font-size:62px;
            .transform(rotate(20deg));
        }
        .icon-diamond {
            bottom:20%;
            right:7%;
            font-size:52px;
            .transform(rotate(-20deg));
        }
        .icon-keyboard {
            bottom:7%;
            right:13%;
            font-size:52px;
            .transform(rotate(20deg));
        }
        .icon-laptop {
            bottom:2%;
            right:24%;
            font-size:54px;
            .transform(rotate(-20deg));
        }
        .icon-rocket {
            bottom:35%;
            right:1%;
            font-size:48px;
            .transform(rotate(-20deg));
        }
    }
    

	&.header {
		padding:200px 0 0 0;
        min-height: 80vh;
        max-width:100%;
        position:relative;
        margin-top:-154px;
        overflow:hidden;
        padding-bottom:25vw;
        .display-flex;
        .align-items(center);
        .justify-content(center);
        > div:not(.headerBg) {
            .flex-center;
            > div {
                display:block;
            }
        }
        h1 {
            margin-top:0;
            position:relative;
            z-index:3;
            padding:0 20px;
            opacity:0;
            .translate3d(0, 70px, 0);
            .animation(fadein 1s ease .6s forwards);
            &:before {
                content:'';
                display:block;
                height:70px;
            }
        }
        p {
            position:relative;
            z-index:3;
            opacity:0;
            .translate3d(0, 70px, 0);
            .animation(fadein 1s ease 1s forwards);
        }
		.btn-white {
			display:block;
			margin:-10vw -125px 0 0;
			width:250px;
			height:80px;
			bottom:6vw;
			font-family:@proxima;
			position:relative;
			z-index:2;
			font-size:26px;
			font-weight:400;
			line-height:80px;
			text-decoration:none;
			.position(absolute, auto, 50%);
			.border-radius(4px);
		}
		&.talent {
		    min-height:0;
		    padding:180px 0 32px;
		    margin-top:-154px;
		    height:auto;
		    .align-items(flex-end);
		    > div {
		        display:block;
		        margin:0;
		        position:static;
		        h1 {
		            margin:0 0 16px;
		        }
		    }
		    .headerBg {
		        display:none;
		    }
		}
    	p {
    		font-family:@circular;
    	}
    	.btn-white {
    		margin:0 auto;
    		padding:0;
    		.position(absolute, auto, 0, 6vw, 0, 2);
    	}
    	&:before {
    	    content:'';
    	    display:block;
    	}
	}
    .headerBg {
    	overflow:hidden;
    	background-color:@blue;
    	.transition(all 1s ease-in-out);
    	.lines {
    	    .position(absolute, auto, 0, 13.4vw, 0);
    	    .transition(all 1s ease-in-out);
    	    i {
    	        display:block;
    	        width:100%;
                height:4px;
                background-color:fadeout(@white, 50%);
                margin:0 0 1.2vw;
                position:relative;
                .transition(all .3s ease);
                &:before {
                    content:'';
                    display:block;
                    width:1vw;
                    height:1vw;
                    background-color:@green;
                    margin-top:-.4vw;
                    .animation(horizontal 5s linear infinite);
                    .border-radius(50%);
                    .position(absolute, 0, 45%);
                }
                &:nth-child(2) {
                    &:before {
                        right:35%;
                        background-color:@red;
                    }
                }
                &:nth-child(3) {
                    &:before {
                        right:55%;
                        background-color:@yellow;
                    }
                }
                &:last-child {
                    &:before {
                        right:65%;
                        background-color:@green;
                    }
                }
    	    }
    	    &.active {
    	        i {
    	            background-color:@white;
    	        }
    	    }
    	}
    	
    	.leftGlobe,
    	.rightGlobe {
    	    width:48vw;
    	    background:@blue url('../img/globe.svg') no-repeat center center/cover;
    	    margin:0 0 -24vw -24vw;
    	    .border-radius(50%);
    	    .position(absolute, auto, auto, 0, 0);
    	    .transition(all 1s ease-in-out);
    	    &:after {
    	        content:'';
    	        display:block;
    	        float:left;
    	        padding-bottom:100%;
    	    }
    	}
    	.rightGlobe {
    	    left:auto;
    	    right:0;
    	    margin:0 -24vw -24vw 0;
    	}
    	.left,
    	.right {
    	    width:48vw;
    	    margin:0 0 -24vw -24vw;
    	    border:.25vw solid @white;
    	    .transform-origin(center center);
    	    .border-radius(50%);
    	    .position(absolute, auto, auto, 0, 0);
    	    .transition(all 1s ease-in-out);
    	    &:after {
    	        content:'';
    	        display:block;
    	        float:left;
    	        padding-bottom:100%;
    	    }
    	    .dot {
    	        width:48vw;
    	        height:48vw;
    	        border:0;
    	        background:0 0!important;
    	        margin:0;
    	        .transition(all 1s ease-in-out);
    	        .position(absolute, -4px, -4px, -4px, -4px);
                &:after {
                    content:'';
                    display:block;
                    width:1.71vw;
                    height:1.71vw;
                    margin:-.98vw -.98vw 0 0;
                    background-color:@blue;
                    border:.25vw solid @white;
                    .border-radius(50%);
                    .position(absolute, 0, 50%);
                    .transition(all 1s ease-in-out);
                }
                img {
                    display:block;
                    width:10.5vw;
                    height:auto;
                    margin:-5vw -5.25vw 0 0;
                    .border-radius(50%);
                    .transform-origin(center center);
                    .transition(all 1s ease-in-out);
                    .position(absolute, -4px, 50%, auto, auto, 1);
                }
                &.android {
                    .transform(rotate3d(0, 0, 1, 45deg));
                    img { .transform(rotate3d(0, 0, 1, -45deg) scale3d(0, 0, 0)); }
                }
                &.angular {
                    .transform(rotate3d(0, 0, 1, 67.5deg));
                    img { .transform(rotate3d(0, 0, 1, -67.5deg) scale3d(0, 0, 0)); }
                }
                &.css3 {
                    .transform(rotate3d(0, 0, 1, 90deg));
                    img { .transform(rotate3d(0, 0, 1, -90deg) scale3d(0, 0, 0)); }
                }
                &.html5 {
                    .transform(rotate3d(0, 0, 1, 112.5deg));
                    img { .transform(rotate3d(0, 0, 1, -112.5deg) scale3d(0, 0, 0)); }
                }
                &.ios {
                    .transform(rotate3d(0, 0, 1, 135deg));
                    img { .transform(rotate3d(0, 0, 1, -135deg) scale3d(0, 0, 0)); }
                }
                &.java {
                    .transform(rotate3d(0, 0, 1, 157.5deg));
                    img { .transform(rotate3d(0, 0, 1, -157.5deg) scale3d(0, 0, 0)); }
                }
                &.javascript {
                    .transform(rotate3d(0, 0, 1, 180deg));
                    img { .transform(rotate3d(0, 0, 1, -180deg) scale3d(0, 0, 0)); }
                }
                &.laravel {
                    .transform(rotate3d(0, 0, 1, 202.5deg));
                    img { .transform(rotate3d(0, 0, 1, -202.5deg) scale3d(0, 0, 0)); }
                }
                &.mysql {
                    .transform(rotate3d(0, 0, 1, 225deg));
                    img { .transform(rotate3d(0, 0, 1, -225deg) scale3d(0, 0, 0)); }
                }
                &.nodejs {
                    .transform(rotate3d(0, 0, 1, 247.5deg));
                    img { .transform(rotate3d(0, 0, 1, -247.5deg) scale3d(0, 0, 0)); }
                }
                &.python {
                    .transform(rotate3d(0, 0, 1, 270deg));
                    img { .transform(rotate3d(0, 0, 1, -270deg) scale3d(0, 0, 0)); }
                }
                &.reactjs {
                    .transform(rotate3d(0, 0, 1, 292.5deg));
                    img { .transform(rotate3d(0, 0, 1, -292.5deg) scale3d(0, 0, 0)); }
                }
                &.ruby {
                    .transform(rotate3d(0, 0, 1, 315deg));
                    img { .transform(rotate3d(0, 0, 1, -315deg) scale3d(0, 0, 0)); }
                }
                &.symphony {
                    .transform(rotate3d(0, 0, 1, 337.5deg));
                    img { .transform(rotate3d(0, 0, 1, -337.5deg) scale3d(0, 0, 0)); }
                }
                &.ember {
                    .transform(rotate3d(0, 0, 1, 360deg));
                    img { .transform(rotate3d(0, 0, 1, -360deg) scale3d(0, 0, 0)); }
                }
                &.golang {
                    .transform(rotate3d(0, 0, 1, 22.5deg));
                    img { .transform(rotate3d(0, 0, 1, -22.5deg) scale3d(0, 0, 0)); }
                }

                &.green { 
                    &:after { background-color:@green; }
                    img {
                        background-color:@green;
                        &.active {
                            background-color:@blue;
                        }
                    }
                }
                &.blue {
                    &:after { background-color:@blue; }
                    img {
                        background-color:@blue;
                        &.active {
                            background-color:@blue;
                        }
                    }
                }
                &.yellow {
                    &:after { background-color:@yellow; }
                    img {
                        background-color:@yellow;
                        &.active {
                            background-color:@blue;
                        }
                    }
                }
                &.red {
                    &:after { background-color:@red; }
                    img {
                        background-color:@red;
                        &.active {
                            background-color:@blue;
                        }
                    }
                }
    	    }
    	}
    	.right {
    	    left:auto;
    	    right:0;
    	    margin:0 -24vw -24vw 0;
    	}
    }
}

.lead {
    text-align:center;
    padding:50px 0;
    h2 {
        margin:20px 0;
        font-size:32px;
        letter-spacing:-1px;
        font-weight:400;
        color:darken(@blue, 30%);
    }
    p {
        color:#555;
        margin:0 auto;
        max-width:830px;
        padding:0 15px;
        font:400 20px/28px @circular;
    }
    &.small {
        p {
            font-size:22px;
            line-height:34px;
        }
    }
}

.main {
    section {
        article {
            margin:0 0 60px;
            padding:50px 0;
            .display-flex;
            .align-items(center);
            .img, .text {
                padding:0 10%;
                max-width:50%;
                text-align:left;
                .flex(1 0 50%);
            }
            .img {
                position:relative;
                &:before {
                    content:'';
                    display:block;
                    float:left;
                }
                img {
                    display:block;
                    width:100%;
                    max-width:280px;
                    height:auto;
                    margin:-100px -140px 0 0;
                    .position(absolute, 50%, 50%);
                }
                [class*="icon-"] {
                    display:block;
                    font-size:200px;
                    width:200px;
                    margin:0 auto;
                }
            }
            .text {
                padding:0 50px 0 0;;
                .flex-center;
                div {
                    margin-left:140px;
                }
            }
            h3 {
                font-weight:400;
                font-size:31px;
                line-height:34px;
                letter-spacing:-1px;
                color:darken(@blue, 30%);
                margin:0 0 20px;
            }
            p {
                font:300 18px/28px @nunito;
                margin:0;
            }
            &:nth-child(even) {
                .flex-direction(row-reverse);
            }
            &.quickOnboarding {
                .img {
                    img {
                        margin: -170px -140px 0 0;
                    }
                }
            }
            &.topDevelopers {
                .img {
                    img {
                        margin: -120px -140px 0 0;
                    }
                    .circle {
                        img {
                            margin: -10px -50px 0 0;
                        }
                    }
                }
            }
            &.teamManagement {
                .img {
                    img {
                        margin: -140px -140px 0 0;
                    }
                }
            }
        }
    }
}

.trusted {
	padding:50px 0;
	text-align:center;
	background-color:#f1f1f1;
	border-top:1px solid #eee;
	h2 {
		margin:0;
		font-size:42px;
		font-weight:400;
		letter-spacing:-1px;
		color:darken(@blue, 30%);
	}
}

.backed {
    background-color:#f6f6f6;
}

.trustedCompanies {
    .displayCompany {
        height:40vw;
        max-height:600px;
        overflow:hidden;
        position:relative;
        .company {
            height:40vw;
            max-height:650px;
            background:transparent none no-repeat center center/cover;
            text-align:center;
            color:@white;
            .flex-center;
            .position(absolute, 0, 0, 0, 0, 0);
            &.active {
                .animation(fadein .5s ease forwards);
            }
            h1 {
                max-width:860px;
                margin:0 auto;
                font:600 50px/60px @circular;
                letter-spacing:-.5px;
            }
            p {
                font:500 15px/21px @circular;
                text-transform:uppercase;
                color:fadeout(@white, 30%);
            }
            button {
                border:0;
                background-color:@white;
                width:40px;
                height:40px;
                margin:50px auto 0;
                .border-radius(50%);
                .flex-center;
                .transition(all .3s ease);
                .transform-origin(center);
                &:before {
                    content:'';
                    display:block;
                    border-top:5px solid transparent;
                    border-bottom:5px solid transparent;
                    border-left:8px solid @black;
                    margin-left:2px;
                }
                &:hover {
                    .transform(scale3d(1.3, 1.3, 1.3));
                }
                &:focus,
                &:active {
                    outline:none;
                }
                &.hiddenPlay {
                    opacity:0;
                    pointer-events:none;
                }
            }
            .iframe {
                max-height:600px;
                background-color:fadeout(@black, 50%);
                opacity:0;
                pointer-events:none;
                .flex-center;
                .position(absolute, 0, 0, 0, 0, 1);
                .transition(all .3s ease);
                iframe {
                    display:block;
                    margin:0 auto;
                    padding:20px 0;
                    min-height:100%;
                    min-width:50vw;
                }
                video {
                    height:90%;
                }
                .icon-close {
                    cursor:pointer;
                    font-size:32px;
                    .position(absolute, 20px, 20px, auto, auto, 10);
                }
                &.active {
                    opacity:1;
                    pointer-events:auto;
                }
            }
            .author {
                margin:40px 0 0;
                font:500 14px/21px @circular;
                text-transform:uppercase;
                color:fadeout(@white, 30%);
                .flex-center;
                img {
                    margin:0 10px 0 0;
                    display:block;
                    width:auto;
                    height:auto;
                    max-height:50px;
                }
                span {
                    text-align:left;
                    font-size:14px;
                    font-weight:normal;
                }
                b {
                    color:@white;
                    font-size:18px;
                }
            }
        }
        .fade {
            display:block;
            pointer-events:none;
            .position(absolute, 0, 0, 0, 0, -1);
            &:after {
                content:'';
                display:block;
                background-color:fadeout(#000, 30%);
                .position(absolute, 0, 0, 0, 0, 0);
            }
        }
    }
    .nav {
        .display-flex;
        .company {
            cursor:pointer;
            position:relative;
            height:10vw;
            max-height:150px;
            .flex-center;
            .flex-basis(0);
            .flex-grow(1);
            &:after {
                content:'';
                display:block;
                background-color:fadeout(@black, 40%);
                .transition(all .3s ease);
                .position(absolute, 0, 0, 0, 0);
            }
            img {
                display:block;
                width:50%;
                max-width:130px;
                height:auto;
            }
            &.active {
                pointer-events:none;
            }
            &.active,
            &:hover {
                &:after { opacity:0; }
            }
            &.mealpal {
                img {
                    width:70%;
                    max-width:170px;
                }
            }
            &.invision {
                img {
                    width:70%;
                    max-width:150px;
                }
            }
        }
    }
}

.partners {
	.grid {
		.display-flex;
		.col-2 {
			max-width:16.66667%;
			.flex(1 0 20%);
			> div {
			    cursor:pointer;
			}
		}
		.col-4 {
			max-width:33.33333%;
			.flex(1 0 40%);
			img {
                margin:0 auto;
                &[src*="video"] {
                    display:block;
                    max-width:364px;
                    width:100%;
                    height:auto;
                }
            }
		}
	}
    
    .logo {
        height:76px;
        margin:10px 0;
        .flex-center;
    }
    p {
        color:@white;
        text-align:center;
        font-size:16px;
        line-height:24px;
        font-weight:500;
        max-width:364px;
        width:100%;
        margin:10px auto;
    }
    .author {
        margin:10px 0 20px;
        b,
        span {
            display:block;
            text-align:center;
            color:@white;
        }
        span {
            font-size:14px;
            font-weight:400;
            color:fadeout(@white, 30%);
        }
    }

	.truelocal,
	.mingl,
	.thechive,
	.booodl,
	.gocus,
	.mealpal {
		.flex-center;
		&:before {
			content:'';
			float:left;
			display:table;
			padding-bottom:100%;
		}

		img {
			display:block;
			width:80%;
			height:auto;
			cursor:pointer;
		}

		iframe {
		    display:block;
		    max-width: 364px;
            width: 100%;
            height: 206px;

		}
	}

	.truelocal {
		background-color:@truelocal;
		img {
			max-width:150px;
		}
	}
	.mingl {
		background-color:@mingl;
		img {
			max-width:177px;
		}
	}
	.thechive {
		background-color:@thechive;
		img {
			max-width:173px;
		}
	}
	.booodl {
		background-color:@booodl;
		img {
			max-width:159px;
		}
	}
	.gocus {
		background-color:@gocus;
		img {
			max-width:113px;
		}
	}
	.mealpal {
		background-color:@mealpal;
		img {
			max-width:163px;
		}
	}
}

.assets {
	margin:0 auto 100px;
	text-align:center;
	.clear;
	img {
		display:block;
		max-width:90px;
		width:100%;
		height:auto;
		margin:0 auto;
	}
	p {

	    color:darken(@blue, 30%);
		font-weight:400;
		margin:20px 0 10px;
		font-size:22px;
		letter-spacing:-.5px;
	}
	span {
	    font:400 16px/22px @nunito;
	}
}

.firms {
    padding:0;
    margin:50px auto;
    list-style:none;
    max-width:900px;
    .flex-center;
    .flex-flow(row wrap);
    li {
        margin:5px;
        max-width:250px;
        height:250px;
        background-color:#ebebeb;
        .flex(1 0 250px);
        .flex-center;
        img {
            display:block;
            width:auto;
            height:auto;
            max-width:150px;
            max-height:60px;
            .grayscale;
        }
    }
}

.kpi {
    text-align:center;
    margin:100px auto;
    .clear;
    b {
        display:block;
        margin:0 0 10px;
        .text-format(@circular, 80px, 80px, #333, 400, normal, normal, -3px);
    }
    span {
        display:block;
        font-size:32px;
        color:darken(@blue, 30%);
        letter-spacing:-1px;
    }
    > div {
        > div {
            &:first-child {
                b { color:@kpi-orange; }
            }
            &:nth-child(2) {
                b { color:@kpi-red; }
            }
            &:last-child {
                b { color:@kpi-blue; }
            }
        }
    }
    &.kpi2 {
        margin:50px 0 100px;
        padding:70px 0 0;
        border-top:1px solid #f0f0f0;
        b {
            &.engineers { color:@kpi-orange; }
            &.clients { color:@kpi-red; }
            &.raised { color:@kpi-blue; }
        }
    }
}

.working {
    background-color:#f6f6f6;
    h2 {
        max-width:650px;
        margin:0 auto 70px;
    }
    .video {
        position:relative;
        width:100%;
        height:auto;
        max-width:560px;
        max-height:315px;
        display:block;
        margin:0 auto 30px;
        img {
            display:block;
            max-width:560px;
            max-height:315px;
            &.video {
                width:100%;
                height:auto;
            }
        }
    }
    iframe {
        display:none;
        margin:0 auto 30px;
        overflow:hidden;
        &.active {
            display:block;
        }
    }
}
.playBtn {
    display:block;
    cursor:pointer;
    opacity:.7;
    width:15%;
    height:auto;
    margin:-5% -7.5% 0 0;
    .border-radius(15px);
    .position(absolute, 50%, 50%);
    .transition(all .3s ease);
    &:hover {
        opacity:1;
        .box-shadow(0 0 5px fadeout(@black, 70%));
    }
}

.beginning {
    h1 {
        max-width:650px;
        margin:0 auto;
        padding:100px 0 50px;
        font-size:42px;
        line-height:60px;
    }
    a.btn.btn-white {
        .flex-center;
    }
}

footer {
    color:@white;
    padding:70px 0 20px;
    background-color:#555;
    ul {
        list-style:none;
        margin:0 0 40px;
        padding:0;
        .display-flex;
        li {
            .flex(1 0 auto);
            &.address {
                max-width:200px;
                font-size:13px;
                line-height:20px;
                span {
                    display:block;
                    .text-format(@circular, 15px, 24px, @white, 400);
                }
            }
            &.social {
                max-width:100px;
                i {
                    display:block;
                    text-align:right;
                    margin:0 0 20px;
                    color:@blue;
                    font-size:34px;
                }
            }
        }
        b {
            display:block;
            margin:0 0 20px;
            .text-format(@circular, 15px, 24px, #bbb, 600, normal, uppercase);
        }
        a {
            display:block;
            text-decoration:none;
            .text-format(@circular, 14px, 26px, @white, 400);
        }
    }
    .row {
        .text-format(@circular, 13px, 18px, #b7b7b7, 400);
        .display-flex;
        .align-items(flex-end);
        .icon-logo,
        .icon-logo-upstack {
            font-size:36px;
        }
        > div {
            text-align:center;
            .flex(1 0 auto);
            &:first-child {
                text-align:left;
                max-width:300px;
            }
            &:last-child {
                text-align:right;
                max-width:300px;
            }
            span {
                margin:0 10px;
            }
            a {
                color:#b7b7b7;
                text-decoration:none;
            }
        }
    }
}

.owl-carousel {
    &.partners {
        display:none;
        .owl-item {
            > div {
                text-align:center;
                display:block;
                padding:20px 0;
                &:before {
                    display:none;
                }
                img {
                    width:140px;
                    height:auto;
                    margin:0 auto 20px;
                }
                iframe {
                    width:auto;
                    height:170px;
                    max-width:none;
                    margin:0 auto;
                }
            }
        }
    }
}

.owl-dots {
    text-align: center;
    position: absolute;
    bottom: 5px;
    width: 100%;
    .backface-visibility(hidden);
}

.owl-dot {
    border-radius: 50px;
    height: 12px;
    width: 12px;
    display: inline-block;
    border:2px solid @white;
    margin-left: 5px;
    margin-right: 5px;
}

.owl-dot.active {
    background:@white;
}

.icon-header {
	font-size:48.5vw;
	display:block;
	overflow:hidden;
    i {
        &:nth-child(n+10):nth-child(-n+58) {
            display:none;
        }
    }
}

.keyframes(horizontal, {
    0 { .translate3d(0, 0, 0); }
    25% { .translate3d(-15px, 0, 0); }
    50% { .translate3d(0, 0, 0); }
    75% { .translate3d(15px, 0, 0); }
    100% { .translate3d(0, 0, 0); }
});

.news {
	text-align:center;
	padding:20px;
	background-color:#f6c304;
	pointer-events:none;
	opacity:0;
	.text-format(@circular, 16px, 30px, @black, 400);
	.position(fixed, 0, 0, auto, 0, -1);
	a {
		color:@blue;
	}
	.icon-close {
		font-size:30px;
		float:right;
		cursor:pointer;
		color:fadeout(@black, 50%);
	}
}

.newsActive {
	padding-top:214px;
	header {
		top:60px;
	}
	.news {
		opacity:1;
		z-index:100;
		pointer-events:auto;
	}
}


@media (max-width: 1800px) {
	.brand {
		&.header {
// 			min-height:900px;
		}
	}
}

@media (max-width: 1310px) {
	.partners {
		.grid {
			.col-4 {
				img[src*="video"] {
					max-width:300px;
				}
			}
		}
	}
}

@media (max-width: @screen-md-max) {
	.brand {
		&.header {
			display:block;
		}
		&.beginning {
			h1 {
				font-size:36px;
				line-height:56px;
			}
		}
	}
	.partners {
		.grid {
			.col-4 {
				.logo {
					height:50px;
				}
				img[src*="video"] {
					max-width:200px;
				}
			}
		}
		.partners {
			.mealpal {
				img { max-width:110px; }
			}
		}
	}
	.main {
	    section {
	        article {
	            .text {
	                div {
	                    margin:0 0 0 20px;
	                }
	            }
	        }
	    }
	}
}

@media (max-width: @screen-sm-max) {
	.brand {
		&.header {
			background-color:@blue;
			padding: 60px 0 50px;
			margin:-60px 0 0;
			min-height:0;
			height:auto;
			h1,
			p,
			.btn-white {
				display:block;
				position:static;
				margin:50px auto 0;
			}
			h1 {
				font-size:42px;
				line-height:50px;
			}
			p {
				font-size:24px;
				line-height:30px;
				padding:0 20px;
				margin:20px 0 50px;
				width:auto;
			}
			&.talent {
				height:auto;
				padding: 180px 0 32px;
				margin-top: -154px;
				p {
					margin:20px 0 0;
				}
			}
			>div:not(.headerBg) {
				margin:0;
				position:static;
				margin-top:0;
				display:block;
			}
		}
		&.header2 {
		    h1 {
		        font-size:40px;
		    }
		}
		.headerBg {
			display:none;
		}
	}
	.main {
		section {
			article {
				margin:0;
				.flex-center;
				.img,
				.text {
					padding:0 15px;
				}
				.img {
					img {
						max-width:200px;
						margin:-100px -100px 0 0;
						.position(absolute, 50%, 50%);
					}
				}
				h3 {
					font-size:24px;
				}
				p {
					font-size:15px;
				}
			}
		}
		.topDevelopers {
			.circle {
				width:40px;
				height:40px;
				margin:-20px -20px 0 0;
				img {
					width:500px;
					margin:-60px -50px 0 0;
				}
			}
		}
	}
	.lead {
		h2 {
			font-size:34px;
		}
		p {
			font-size:22px;
			line-height:30px;
			margin:0 auto;
			max-width:520px;
		}
	}
	.partners {
		display:none;
	}
	.owl-carousel {
		&.partners {
			display:block;
		}
	}
	.leftMenu {
		padding-top:20px;
		max-height:100vh;
		max-width:270px;
		.display-flex;
		.flex-flow(column nowrap);
		.justify-content(space-between);
		ul {
			margin:0;
			padding:0;
			list-style:none;
			li {
				&.title {
					padding:20px 0 0;
					margin:0 60px 0 40px;
					border-bottom:1px solid fadeout(@white, 80%);
					.text-format(@circular, 14px, 34px, fadeout(@white, 50%), 400, normal, uppercase);
				}
			}
			a {
				display:block;
				padding:0 50px 0 40px;
				.text-format(@circular, 16px, 36px, @white, 400);
			}
		}
		.footer {
			padding:0 30px 20px;
			.icon-logo,
			.icon-logo-upstack {
				font-size:36px;
				color:fadeout(@white, 50%);
			}
			.privacy {
				padding: 10px 0 10px 0;
				a {
					margin:0 10px 0 0;
					.text-format(@circular, 12px, 18px, fadeout(@white, 50%), 400);
				}
			}
			.copy {
				.text-format(@circular, 12px, 18px, fadeout(@white, 50%), 400);
			}
		}
	}
	.main {
	    section {
	        article {
	            .text {
	                div {
	                    margin:30px 0 0;
	                }
	            }
	        }
	    }
	}
	.trustedCompanies {
	    display:none;
	}
}

@media (max-width: @screen-xs-max) {
	header {
		nav {
			display:block;
		}
	}
	.main {
		section {
			article {
				div {
					padding:0 15px;
				}
				h3 {
					font-size:24px;
				}
				p {
					font-size:15px;
					line-height:22px;
				}
			}
		}
	}
	.lead {
		padding:70px 15px;
		h2 {
			font-size:22px;
			max-width:300px;
			margin:0 auto 20px;
		}
		p {
			font-size:18px;
			line-height:28px;
			padding:0 10px;
			margin:0 auto;
		}
		&.small {
			p {
				font-size: 18px;
				line-height: 28px;
			}
		}
	}
	.main {
		section {
			article {
				display:block;
				margin:0 0 70px;
				padding: 0 30px 40px;
				div {
					max-width:none;
				}
				img {
					max-width:200px;
				}
				p, h3 {
					margin-top:0;
					text-align:center;
				}
				.img,
				.text {
					max-width:none;
				}
				.img {
				    text-align:center;
					height:200px;
					&:before {
						display:none;
					}
					img {
						margin-top:0;
						top:0;
					}
					[class*="icon-"] {
					    font-size:30vw;
					}
					&.svgIcons {
					    height:auto;
					}
				}
			}
		}
		.topDevelopers {
			.circle {
				margin-top:0;
			}
		}
	}
	.brand {
		&.header {
			display:block;
			h1 {
				font-size:30px;
				line-height:40px;
				width:auto;
				margin:20px auto 0;
			}
			p {
				font-size:18px;
				line-height:24px;
				text-align:center;
				margin:10px 0 0;
			}
			.btn-white {
				width:200px;
				height:60px;
				font-size:20px;
				line-height:60px;
				font-weight:400;
			}
			> div:not(.headerBg) {
				position:static;
				margin-top:0;
				display:block;
			}
		}
		&.header2 {
		    .randomIcons {
		        display:none;
		    }
		    .partners {
		        width:100%;
		        padding:15px 0;
		        .flex-flow(row wrap);
		        i {
		            text-align:center;
		            max-width:33.3333%;
		            margin:15px 0 0;
		            font-size:6vw;
		            .flex(1 0 33.3333%);
		        }
		        .icon-winc,
		        .icon-thechive {
		            font-size:5vw;
		        }
		    }
		    h1 {
		        margin:50px 0 30;
		        padding:0 30px;
		        font-size:30px;
		        line-height:40px;
		        display:block;
		        b {
		            display:inline;
		            margin:0;
		        }
		    }
		    p {
		        font-size:18px;
		        line-height:24px;
		        margin:10px 0 50px;
		        padding:0 40px;
		        height:auto;
		        overflow:visible;
		        b {
		            display:inline;
		        }
		    }
		    .btn-white {
		        margin:0 auto;
		    }
		}
		.randomIcons {
		    i {
		        font-size:30px!important;
		    }
		}
	}
	.assets {
		margin:0 0 50px 0;
		padding:0 15px;
		p {
			height:50px;
			margin:20px 0 30px;
		}
	}
	.kpi {
		span {
			margin-bottom:30px;
			font-size:24px;
		}
		b {
			font-size:60px;
			line-height:60px;
			margin:0;
		}
	}
	.working {
		padding:0 0 50px;
		iframe {
			max-width:300px;
			height:auto;
		}
	}
	footer {
		padding:30px 0 20px;
		ul {
			.flex-flow(row wrap);
			li {
				margin: 0 0 50px;
				max-width:50%;
				.flex(1 0 50%);
				&.social {
					max-width:100%;
					margin:0;
					.display-flex;
					.justify-content(center);
					.flex(1 0 100%);
					a {
						margin:0 10px;
					}
				}
			}
		}
		.row {
			display:block;
			> div {
				&.col-sm-4 {
					text-align:center;
					&:first-child,
					&:last-child {
						max-width:none;
					}
				}
			}
		}
	}
	.trusted {
		padding:30px 0;
		h2 {
			max-width:300px;
			font-size:32px;
			margin:0 auto;
		}
	}
	.btn {
		height:60px;
	}
	.brand {
		&.beginning {
			h1 {
				margin:0 auto;
				width:310px;
				font-size:32px;
				line-height:40px;
			}
		}
	}
	.newsActive {
        padding-top:214px;
        header {
            top:86px;
        }
        .news {
            opacity:1;
            z-index:100;
            pointer-events:auto;
            font-size:14px;
            line-height:24px;
            padding:16px;
            .container {
                padding:0;
            }
        }
    }
}

.keyframes(jumpin, {
	0% { .transform(scale3d(1, 1, 1) translate3d(0, 80px, 0)); }
	60% { .transform(scale3d(1.2, 1.2, 1.2) translate3d(0, -10px, 0)); }
	100% { .transform(scale3d(1, 1, 1) translate3d(0, 0, 0)); }
});


.keyframes(fadein, {
    from { .translate3d(0, 100%, 0); }
    to { opacity:1; .translate3d(0, 0, 0); }
});
