.brand {
	&.header {
		&.partnerNetwork {
			.btn-white {
				position:static;
				margin:20px auto;
			}
		}
	}
}

.main {
	section {
	    &.talents {
	        max-width:1100px;
	        margin:0 auto;
	    }
		article {
			.img {
				.icon-cultured {
					display:block;
					margin:0 auto;
					font-size:350px;
				}
			}
			&.existing,
			&.designers,
			&.incubators {
				.img {
					.flex-center;
					img {
						position:static;
						margin:0;
					}
				}
			}
			&.topDevelopers,
			&.existing,
			&.designers,
			&.incubators {
				.text {
					p {
						font-size:18px;
						line-height:26px;
					}
				}
			}
		}
	}
}

.beginning {
	&.started {
		h1 {
			padding:100px 0 20px;
		}
		p {
			margin:0 auto;
			padding:0 0 50px;
		}
	}
}

.talent-logos {
	margin:20px 0 50px;
	overflow:hidden;
	position:relative;
	.display-flex;
	ul {
		list-style:none;
		padding:0;
		margin:0;
		.display-flex;
		.animation(roll 20s linear infinite);
		li {
			margin:0 30px;
			img {
				width:auto;
				height:60px;
			}
		}
	}
	&:after,
	&:before {
		content:'';
		display:block;
		top:0;
		bottom:0;
		width:100px;
		position:absolute;
		z-index:1;
	}
	&:after {
		right:0;
		background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
		background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
		background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 );
	}
	&:before {
		left:0;
		background: -moz-linear-gradient(left, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
		background: -webkit-linear-gradient(left, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
		background: linear-gradient(to right, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 );
	}
}

.keyframes(roll, {
	from { transform:translateX(0); }
	to { transform:translatex(-1566px) }
});

@media (max-width:767px) {
	.talent-logos {
		ul {
			li {
				margin: 0 15px;
				img {
					height:40px;
				}
			}
		}
	}
	.main section article {
	    margin:0 0 30px;
	    padding: 0 0 40px;
	    .text {
	        padding:0;
	    }
	    .img  {
            margin:0;
            .icon-cultured {
                font-size:200px;
            }
        }
        p {
            font-size:15px;
            line-height:24px;
        }
        &.topDevelopers,
        &.quickOnboarding,
        &.teamManagement {
            .img img {
                margin:0 -100px 0 0;
            }
            .circle {
                img {
                    width:100px;
                }
            }
            .text {
                p {
                    font-size:15px;
                }
            }
        }
	}
	.lead {
	    padding:50px 0;
	}
	.beginning {
        &.started {
            p {
                font-size:24px;
                line-height:28px;
                margin:0 auto;
                padding:0 15px 50px;
            }
        }
    }
}
