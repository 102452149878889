.brand {
    &.talent {
        p {
            font-size:24px;
            line-height:30px;
            padding:0 30px;
        }
        .headerBg {
            .icon-header {
                i {
	                &:nth-child(n+10):nth-child(-n+58) {
		                display:inline;
	                }
                    &:nth-child(n+59):nth-child(-n+74) {
                        display:none;
                    }
                }
            }
            .left,
            .right {
                display:none;
            }
        }
    }
}

.talent {
    &.main {
        position:relative;
    }
}

.gradient-shadow {
    position:relative;
    &:after {
        content:'';
        opacity:.2;
        display:block;
        height:50px;
        width:100vw;
        margin-right:-50vw;
        border-bottom:1px solid #ddd;
        background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(0,0,0,0.1) 100%);
        background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(0,0,0,0.1) 100%);
        background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(0,0,0,0.1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#1a000000',GradientType=0 );
        .position(absolute, auto, 50%, 0);
    }
    &.top {
        &:after {
            border-bottom:0;
            border-top:1px solid #ddd;
            background: -moz-linear-gradient(bottom, rgba(255,255,255,1) 0%, rgba(0,0,0,0.1) 100%);
            background: -webkit-linear-gradient(bottom, rgba(255,255,255,1) 0%,rgba(0,0,0,0.1) 100%);
            background: linear-gradient(to top, rgba(255,255,255,1) 0%,rgba(0,0,0,0.1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1a000000', endColorstr='#ffffff',GradientType=0 );
            .position(absolute, 0, 50%, auto);
        }
    }
}

.meetOurTalent {
    .display-flex;
    .assets {
        text-align:left;
        max-width:40%;
        padding-bottom:100px;
        .flex(1 0 40%);
        article {
            margin: 0 0 0 10px;
            padding: 0 40px 20px;
            display:block;
            border-left:4px solid @blue;
            position:relative;
            p {
                margin:0 0 5px;
                text-align:left;
                .text-format(@circular, 24px, 30px, @black, 400);
                &:before {
                    content:'';
                    display:block;
                    width:28px;
                    height:28px;
                    border:4px solid @blue;
                    background-color:@white;
                    margin-top:2px;
                    .border-radius(50%);
                    .box-sizing(border-box);
                    .position(absolute, auto, auto, auto, -16px);
                }
            }
            span {
                .text-format(@circular, 16px, 24px, @black, 400);
            }
            &:first-child {
                &:before {
                    content:'';
                    width:30px;
                    height:6px;
                    background-color:@white;
                    .position(absolute, 0, auto, auto, -16px);
                }
            }
            &:last-child {
                &:before {
                    content:'';
                    width:30px;
                    background-color:@white;
                    .position(absolute, 34px, auto, 0, -16px);
                }
            }
            &:last-of-type {
                border-left:4px solid transparent;
            }
        }
    }
    .devs {
        max-width:56%;
        position:relative;
        .flex(1 0 56%);
        .bgLines {
            display:none;
            em {
                &:after,
                &:before {
                    content:'';
                    display:block;
                    width:15px;
                    height:15px;
                    position:absolute;
                    background-color:@red;
                    .border-radius(50%);
                    .position(absolute, 100px, 65px);
                }
                &:after {
                    top: 715px;
                    right: 270px;
                    background-color:@blue;
                }
            }
            i {
                &:before {
                    content:'';
                    width:200px;
                    height:490px;
                    display:block;
                    border-right:4px solid #ceedf8;
                    border-bottom:4px solid #ceedf8;
                    .border-radius(0 0 107px 0);
                    .position(absolute, -50px, 70px, auto, auto);
                }
                &:after {
                    content:'';
                    display:block;
                    width:300px;
                    height:220px;
                    border-left:4px solid #ceedf8;
                    border-top:4px solid #ceedf8;
                    .border-radius(56px 0 0 0);
                    .position(absolute, 440px, auto, auto, 121px);
                }
                &:nth-child(2) {
                    &:before {
                        height:473px;
                        right:87px;
                        .border-radius(0 0 90px 0);
                    }
                    &:after {
                        height:220px;
                        left:104px;
                        top:423px;
                        .border-radius(73px 0 0 0);
                    }
                    em {
                        &:before {
                            top:310px;
                            right:65px;
                            background-color:@yellow;
                        }
                    }
                }
                &:nth-child(3) {
                    &:before {
                        height:456px;
                        right:105px;
                        .border-radius(0 0 73px 0);
                    }
                    &:after {
                        height:220px;
                        left:87px;
                        top:406px;
                        .border-radius(90px 0 0 0);
                    }
                    em {
                        &:before {
                            top:665px;
                            right:112px;
                            background-color:@green;
                        }
                    }
                }
                &:nth-child(4) {
                    &:before {
                        height:439px;
                        right:122px;
                        .border-radius(0 0 56px 0);
                    }
                    &:after {
                        height:220px;
                        left:70px;
                        top:389px;
                        .border-radius(107px 0 0 0);
                    }
                    em {
                        &:before {
                            top:280px;
                            right:117px;
                            background-color:@blue;
                        }
                        &:after {
                            top:1150px;
                            right:640px;
                            background-color:@blue;
                            z-index:1;
                        }
                    }
                }
            }
            b {
                &:before {
                    content:'';
                    width:100vw;
                    height:160px;
                    display:block;
                    margin:0;
                    border-right:4px solid #ceedf8;
                    border-bottom:4px solid #ceedf8;
                    margin-right:75px;
                    .border-radius(0 0 107px 0);
                    .position(absolute, 910px, 50%, 0, auto);
                }
                &:after {
                    content:'';
                    width:200px;
                    display:block;
                    margin:0;
                    border-right:4px solid #ceedf8;
                    border-top:4px solid #ceedf8;
                    margin-right:275px;
                    .border-radius(0 107px 0 0);
                    .position(absolute, 1019px, 50%, 0, auto);
                }
                &:nth-of-type(2) {
                    &:before {
                        top:893px;
                        margin-right:92px;
                        .border-radius(0 0 90px 0);
                    }
                    &:after {
                        margin-right:292px;
                        top:1036px;
                        .border-radius(0 90px 0 0);
                    }
                    em {
                        &:after {
                            top: 1270px;
                            right: 606px;
                            background-color:@yellow;
                        }
                        &:before {
                            top: 1030px;
                            right: 550px;
                            background-color:@green;
                            z-index:1;
                        }
                    }
                }
                &:nth-of-type(3) {
                    &:before {
                        top:876px;
                        margin-right:109px;
                        .border-radius(0 0 73px 0);
                    }
                    &:after {
                        margin-right:309px;
                        top:1053px;
                        .border-radius(0 73px 0 0);
                    }
                    em {
                        &:after {
                            top: 1270px;
                            right: 606px;
                            background-color:@yellow;
                        }
                        &:before {
                            top: 1030px;
                            right: 1170px;
                            background-color:@yellow;
                        }
                    }
                }
                &:nth-of-type(4) {
                    &:before {
                        top:859px; 
                        margin-right:126px;
                        .border-radius(0 0 56px 0);
                    }
                    &:after {
                        margin-right:326px;
                        top:1070px;
                        .border-radius(0 56px 0 0);
                    }
                    em {
                        &:before {
                            top: 1013px;
                            right: 870px;
                            background-color:@red;
                        }
                        &:after {
                            top: 913px;
                            right: 405px;
                            background-color:@blue;
                        }
                    }
                }
            }
        }
        .developer {
            position:relative;
            z-index:3;
            text-align:center;
            max-width:150px;
            float:left;
            margin:0 0 -30px 0;
            clear:both;
            figure {
                width:150px;
                height:150px;
                overflow:hidden;
                border:4px solid @blue;
                position:relative;
                margin:0 0 5px 0;
                display:block;
                .border-radius(50%);
                img {
                    width:100%;
                    height:auto;
                    margin:auto;
                    .position(absolute, -500px, -500px, -500px, -500px);
                }
            }
            b {
                display:block;
                .text-format(@circular, 30px, 32px, @black, 400);
            }
            em,
            span {
                display:block;
                .text-format(@circular, 16px, 20px, @black, 400);
            }
            .details {
                width:300px;
                border:4px solid @blue;
                padding:20px 30px 20px 40px;
                background-color:@white;
                margin:0 0 0 40px;
                text-align:left;
                .border-radius(40px);
                .position(absolute, 0, auto, auto, 100%);
                .text-format(@circular, 22px, 32px, @black, 400);
                .transition(all .3s ease);
                &:before,
                &:after {
                    content: '';
                    display:block;
                    margin-top:-19px;
                    border-right:32px solid @blue;
                    border-top:19px solid transparent;
                    border-bottom:19px solid transparent;
                    .position(absolute, 50%, 100%);
                }
                &:after {
                    margin-right:-7px;
                    border-right:32px solid @white;
                }
                &:hover {
                    margin:-5px 0 0 35px;
                    .shadow {
                        margin:10px 0 0 10px;
                    }
                }
                .icon-quotes-left,
                .icon-quotes-right {
                    font-size:16px;
                    margin:0 5px 0 -18px;
                    position:relative;
                    top:-5px;
                    color:fadeout(@black, 50%);
                }
                .icon-quotes-right {
                    margin:0 0 0 5px;
                }
                .shadow {
                    width:100%;
                    height:100%;
                    background-color:fadeout(@blue, 90%);
                    .border-radius(40px);
                    .position(absolute, 14px, auto, auto, 14px, -1);
                    .transition(all .3s ease);
                    &:before {
                        content: '';
                        display:block;
                        margin-top:-19px;
                        border-right:32px solid fadeout(@blue, 90%);
                        border-top:19px solid transparent;
                        border-bottom:19px solid transparent;
                        .position(absolute, 50%, 100%);
                    }
                }
            }
            &.right {
                float:right;
                .details {
                    left:auto;
                    right:100%;
                    margin:0 40px 0 0;
                    &:before,
                    &:after {
                        border-right:0;
                        border-left:32px solid @blue;
                        right:auto;
                        left:100%;
                    }
                    &:after {
                        border-right:0;
                        border-left:32px solid @white;
                        margin: -19px 0 0 -7px;
                    }
                    .shadow {
                        &:before {
                            right:auto;
                            left:100%;
                            border-right:0;
                            border-left:32px solid fadeout(@blue, 90%);
                        }
                    }
                    &:hover {
                        margin:-5px 45px 0 0;
                        .shadow {
                            margin:10px 0 0 10px;
                        }
                    }
                }
            }
        }
    }
}

.talent-beta {
    .meetOurTalent {
        max-width:90%;
        margin:0 auto;
        padding-bottom:70px;
        .display-flex;
        .justify-content(space-between);
        .assets {
            text-align:left;
            max-width:40%;
            margin:0;
            padding:0;
            .flex(1 0 40%);
            article {
                border:0;
                margin:0;
                padding:0 0 20px;
                display:block;
                position:relative;
                p {
                    margin:0 0 5px;
                    text-align:left;
                    .text-format(@circular, 24px, 30px, darken(@blue, 30%), 400, normal, none, -.5px);
                    &:before {
                        display:none;
                    }
                }
                span {
                    .text-format(@nunito, 16px, 24px, #777, 400);
                }
                &:last-child:before {
                    display:none;
                }
            }
        }
        .devs {
            max-width:55%;
            padding:40px 0 0;
            .developer {
                max-width:none;
                margin:0 0 40px;
                float:none;
                position:relative;
                &:before,
                &:after {
                    content:'';
                    display:table;
                    clear:both;
                }
                b, span {
                    position:absolute;
                    right:0;
                    bottom:0;
                }
                span {
                    font-size:16px;
                    color:#777;
                }
                b {
                    bottom:12px;
                    color:@blue;
                    font-size:20px;
                    letter-spacing:-.5px;
                }
                figure {
                    width:150px;
                    height:150px;
                    border:0;
                    float:left;
                    margin:0 50px 0 0;
                    background:transparent none no-repeat center center/cover;
                    .box-shadow(inset 0 0 10px fadeout(@black, 50%)); 
                }
                .details {
                    width:auto;
                    position:static;
                    border:0;
                    padding:40px 0;
                    margin:0;
                    font:300 italic 22px/30px @nunito;
                    color:#555;
                    .shadow { display:none; }
                    &:before,
                    &:after { display:none; }
                    &:hover {
                        margin:0;
                    }
                    .icon-quotes-left,
                    .icon-quotes-right {
                        color:#ddd;
                    }
                }
            }
        }
    }
}

.top1 {
    overflow:hidden;
    .lead {
        position:relative;
        p {
            padding:0 30px;
            margin:0 auto;
        }
    }
    .wrapper {
        margin:0 auto 100px;
        max-width:850px;
        .display-flex;
        ul,
        figure {
            margin:0;
            padding:0;
            
        }
        ul {
            list-style:none;
            max-width:50%;
            .flex(1 0 50%);
            li {
                font-size:28px;
                max-height:60px;
                position:relative;
                .display-flex;
                .align-items(center);
                .text-format(@circular, 30px, 60px, #201e1f, 400);
                &.title {
                    display:block;
                    max-width:380px;
                    padding:10px 0;
                    margin:0;
                    max-height:none;
                    .text-format(@circular, 30px, 40px, #201e1f, 300);
                    b {
                        margin:0;
                    }
                }
                i {
                    width:20px;
                    height:20px;
                    text-align:center;
                    display:inline-block;
                    color:@white;
                    font-size:20px;
                    background-color:@blue;
                    margin:5px 0 0 10px;
                    .border-radius(50%);
                    .flex-center;
                }
                p {
                    pointer-events:none;
                    position:absolute;
                    background-color:@white;
                    font:14px/21px @nunito;
                    padding:10px 15px;
                    margin:0;
                    top:100%;
                    z-index:1;
                    left:32px;
                    right:20px;
                    border:1px solid fadeout(@black, 90%);
                    border-bottom:3px solid @black;
                    overflow:hidden;
                    max-height:0;
                    opacity:0;
                    transition:all .3s ease;
                    &:before,
                    &:after {
                        content:'';
                        display:block;
                        position:absolute;
                        top:-10px;
                        left:10px;
                        border-left:10px solid transparent;
                        border-right:10px solid transparent;
                        border-bottom:10px solid fadeout(@black, 90%);
                    }
                    &:after {
                        top:-8px;
                        border-bottom:10px solid @white;;
                    }
                }
                b {
                    font-size:36px;
                    margin-right:20px;
                }
                &.green {
                    b { color:@green; }
                    p { border-bottom:3px solid @green; }
                }
                &.blue {
                    b { color:@blue; }
                    p { border-bottom:3px solid @blue; }
                }
                &.red {
                    b { color:@red; }
                    p { border-bottom:3px solid @red; }
                }
                &.lightblue {
                    b { color:fadeout(@blue, 50%); }
                    p { border-bottom:3px solid fadeout(@blue, 50%); }
                }
                &.yellow {
                    b { color:@yellow; }
                    p { border-bottom:3px solid @yellow; }
                }
                &.black {
                    span {
                        font-weight:bold;
                    }
                }
                &:hover p {
                    opacity:1;
                    max-height:100px;
                    overflow:visible;
                } 
            }
        }
        figure {
            max-width:45%;
            .flex(1 0 45%);
            img {
                display:block;
                width:auto;
                height:100px;
                margin:0 auto;
            }
            div {
                margin:0 auto 10px;
                position:relative;
                height:55px;
                text-align:center;
                opacity:0;
                .text-format(@circular, 24px, 55px, @white, 300);
                &:before {
                    content:'';
                    display:block;
                    border-top: 55px solid #32d9c3;
                    border-left: 30px solid transparent;
                    border-right: 30px solid transparent;
                    .position(absolute, 0, 0, auto, 0, -1);
                }
                &.blue {
                    &:before {
                        border-top-color:@blue;
                        .position(absolute, 0, 34px, auto, 34px, -1);
                    }
                }
                &.user-star {
                    text-align:left;
                    color:@blue;
                    font-weight:500;
                    font-size:28px;
                    &:before {
                        display:none;
                    }
                    img {
                        display:inline;
                        height:55px;
                        width:auto;
                        float:left;
                        margin:0 10px 0 165px;
                    }
                }
                &.red {
                    &:before {
                        border-top-color:@red;
                        .position(absolute, 0, 68px, auto, 68px, -1);
                    }
                }
                &.lightblue {
                    &:before {
                        border-top-color:fadeout(@blue, 50%);
                        .position(absolute, 0, 102px, auto, 102px, -1);
                    }
                }
                &.yellow {
                    &:before {
                        border-top-color:@yellow;
                        .position(absolute, 0, 136px, auto, 136px, -1);
                    }
                }
            }
        }
    }
}


.lead {
    &.bottom {
        padding-top:0;
    }
}

.topCompanies {
    max-width:700px;
    margin:0 auto;
    .flex-center;
    .flex-flow(row wrap);
    div {
        margin:0 0 30px;
        padding:10px 30px;
        max-width:33.3333%;
        .flex(1 0 33.3333%);
        img {
            opacity:0;
            display:block;
            margin:0 auto;
            width:auto;
            height:auto;
            max-width:100%;
            &.animated {
                opacity:1;
            }
        }
    }
}

.technologies {
    max-width:600px;
    margin:0 auto 150px;
    img {
        display:block;
        margin:0 auto -5.55%;
        width:100%;
        height:auto;
        &:nth-child(even) {
            max-width:78.40%;
        }
    }
}

.worldStats {
    margin:0 0 20px;
    .display-flex;
    .justify-content(space-between);
    div {
        text-align:center;
        b {
            display:block;
            width:80px;
            height:80px;
            margin:0 auto 10px;
            border:2px solid transparent;
            .text-format(@circular, 24px, 76px, @black, 400);
            .border-radius(50%);
        }
        span {
            .text-format(@circular, 20px, 30px, @black, 400);
        }
        &.yellow {
            b {
                color:@yellow;
                border-color:@yellow;
            }
        }
        &.blue {
            b {
                color:@blue;
                border-color:@blue;
            }
        }
        &.green {
            b {
                color:@green;
                border-color:@green;
            }
        }
        &.red {
            b {
                color:@red;
                border-color:@red;
            }
        }
        &.pink {
            b {
                color:#f677c4;
                border-color:#f677c4;
            }
        }
    }
}

.worldMap {
    max-width:700px;
    margin:0 auto 100px;
    position:relative;
    img {
        display:block;
        width:100%;
        height:auto;
    }
    div {
        border:2px solid transparent;
        .border-radius(50%);
        &:after,
        &:before {
            content:'';
            display:block;
            border:2px solid transparent;
            .border-radius(50%);
            .position(absolute);
            .transform-origin(center center);
            .transform(scale3d(0, 0, 0));
            .transition(all .3s ease);
        }
        &.active {
            &:after,
            &:before {
                .transform(scale3d(1, 1, 1));
            }
        }
        &.red {
            &:after {
                width:7.01%;
                height:10.92%;
                border-color:@red;
                top:46%;
                left:23%;
            }
            &:before {
                width:6.14%;
                height:9.56%;
                border-color:@red;
                top:44%;
                left:12%;
            }
        }
        &.green {
            width:8.77%;
            height:13.66%;
            border-color:@green;
            .position(absolute, 64%, auto, auto, 28%);
            .transform-origin(center center);
            .transform(scale3d(0, 0, 0));
            .transition(all .3s ease);
            &.active {
                .transform(scale3d(1, 1, 1));
            }
            &:after {
                width: 96%;
                height: 96%;
                top: 61%;
                left: 29%;
                border-color:@green;
            }
            &:before {
                width: 60%;
                height: 60%;
                top: 168%;
                left: -27%;
                border-color:@green;
            }
        }
        &.yellow {
            &:after {
                width: 8.77%;
                height: 13.66%;
                top: 36%;
                left: 50%;
                border-color:@yellow;
            }
            &:before {
                width: 5.70%;
                height: 8.87%;
                top: 42%;
                left: 55%;
                border-color: @yellow;
            }
        }
        &.pink {
            &:after {
                width: 5.26%;
                height: 8.19%;
                top: 42%;
                left: 47%;
                border-color:#f677c4;
            }
            &:before {
                width: 3.94%;
                height: 6.14%;
                top: 47%;
                left: 45%;
                border-color: #f677c4;
            }
        }
        &.hq {
            opacity:0;
            width:0;
            height:0;
            border:0;
            .transform(translate3d(0 50px 0));
            .transition(all .3s ease);
            .position(absolute, 50%, auto, auto, 29%);
            img {
                display:block;
                width:60px;
                height:auto;
                margin-right:-30px;
                .position(absolute, auto, 50%, 100%, auto);
            }
            &.active {
                opacity:1;
                .transform(translate3d(0 0 0));
            }
        }
    }
}

@media (max-width: @screen-md-max) {
	.meetOurTalent {
		.devs {
			.bgLines {
				display:none;
			}
			.developer {
				max-width:160px;
				figure {
					width:160px;
					height:160px;
				}
				.details {
					width:300px;
					margin:0 0 0 40px;
					font-size:24px;
					line-height:30px;
					&:before,
					&:after {
						top:60px;
					}
					&:hover {
						margin:-5px 0 0 40px;
					}
				}
				&:nth-child(3) {
					margin-top:50px;
				}
				&.right {
					.details {
						margin:0 40px 0 0;
						&:hover {
							margin:-5px 40px 0 0;
						}
					}
				}
			}
		}
		.assets  {
			padding:0;
		}
	}
	.top1 .wrapper figure div.user-star img {
		margin: 0 10px 0 165px;
	}
}

@media (max-width: @screen-sm-max) {
	.meetOurTalent {
		.assets {
			max-width:60%;
			.flex(1 0 60%);
			article {
				p {
					font-size:28px;
					line-height:44px;
					margin:0;
				}
				span {
					font-size:18px;
					line-height:28px;
				}
			}
		}
		.devs {
			max-width:40%;
			.flex(1 0 40%);
			.developer {
				float:right;
				.details {
					display:none;
				}
				&:nth-child(3) {
					margin:0;
				}
			}
		}
	}
	
    .top1 {
        overflow:hidden;
        .lead {
            position:relative;
            p {
                padding:0;
            }
        }
        .wrapper {
            ul {
                li {
                    &.title {
                        font-size:32px;
                        line-height:40px;
                        padding:20px 0;
                        b {
                            font-size:36px;
                        }
                    }
                }
            }
            figure {
                max-width:40%;
                .flex(1 0 40%);
                img {
                    height:110px;
                }
                div {
                    &:before {
                        border-left-width:20px;
                        border-right-width:20px;
                    }
                    &.blue {
                        &:before { left:25px; right:25px; }
                    }
                    &.red {
                        &:before { left:50px; right:50px; }
                    }
                    &.lightblue {
                        &:before { left:75px; right:75px; }
                    }
                    &.yellow {
                        &:before { left:100px; right:100px; }
                    }
                    &.user-star {
                        img {
                            margin: 0 10px 0 115px;
                        }
                    }
                }
            }
        }
    }
	
}

@media (max-width: @screen-xs-max) {
	.meetOurTalent {
		.flex-flow(row-reverse wrap);
		.devs {
			max-width:100%;
			.flex(1 0 100%);
			.developer {
				margin:0 auto 50px;
				float:none;
				&.right {
					float:none;
				}
				&:nth-child(3) {
					margin:0 auto 50px;
				}
			}
		}
		.assets {
			max-width:100%;
			.flex(1 0 100%);
			article {
				padding: 0 30px 30px;
			}
		}
	}
	.brand {
		&.talent {
			p {
				font-size:20px;
				line-height:24px;
				width:auto;
				padding:0 30px;
				margin:10px 0 0;
			}
		}
	}
	
    .top1 {
        .wrapper {
            width:auto;
            display:block;
            figure {
                max-width:300px;
                margin:50px auto 0;
                div {
                    &.user-star {
                        img {
                            margin: 0 10px 0 117px;
                        }
                    }
                }
            }
            ul {
                max-width:none;
                li {
                    font-size:18px;
                    margin:0;
                    b {
                        font-size:28px;
                    }
                    &.title {
                        font-size:28px;
                        b {
                            display:block;
                            float:left;
                        }
                    }
                }
            }
        }
    }
	
	.btn-auto {
		padding:0 20px;
		font-size:18px;
	}
	.worldStats {
		display:block;
		> div {
			margin:0 auto 50px;
		}
	}
	.worldMap {
		div {
			&:after,
			&:before {
				.transform(scale3d(1,1,1));
			}
			&.green,
			&.hq {
				opacity:1;
				.transform(scale3d(1,1,1));
			}
		}
	}
	.topCompanies {
		div {
			img {
				opacity:1;
			}
		}
	}
}