.jobs {
    list-style:none;
    max-width:1200px;
    margin:0 auto 70px;
    min-height:500px;
    padding:0 15px;
    li {
        &.loader {
            &:before {
                content:'';
                display:block;
                margin:0 auto;
                width:30px;
                height:30px;
                border:3px solid @blue;
                border-left:3px solid fadeout(@black, 90%);
                border-top:3px solid fadeout(@black, 90%);
                .transform-origin(center center);
                .border-radius(50%);
                .box-sizing(border-box);
                .animation(spin .6s linear infinite);
            }
        }
        &:not(.loader) {
            background-color:@white;
            padding:30px 50px;
            margin:0 0 10px;
            .border-radius(4px);
            .box-shadow(0 3px 6px fadeout(@black, 90%));
            .display-flex;
            .align-items(center);
            .justify-content(space-between);
            * {
                display:block;
            }
            b {
                 margin:0 0 5px;
                .text-format(@circular, 20px, 24px, darken(@blue, 30%), 600);
            }
            span {
                margin:0 0 5px;
                .text-format(@circular, 24px, 32px, @blue, 400);
            }
            em {
                .text-format(@circular, 14px, 24px, fadeout(@black, 50%), 400, normal);
            }
            label {
                text-align:right;
                margin:0;
                padding:0;
                border:0;
                .text-format(@circular, 18px, 24px, fadeout(@black, 50%), 400, normal);
            }
            strong {
                text-align:right;
                .text-format(@circular, 20px, 32px, @blue, 400, normal);
            }
        }
    }
}

.careersWrapper {
    background-color:#f6f6f6;
    .filters {
        margin:20px auto;
        .display-flex;
        .align-items(center);
        .justify-content(flex-end);
        span {
            margin:0 10px 0 0;
        }
        select {
            color:darken(@blue, 30%);
            font-weight:bold;
            height:40px;
            padding:0 15px;
            width:auto;
            border:1px solid #ddd;
            .border-radius(0);
            &:focus {
                .box-shadow(none);
            }
        }
    }
}

.keyframes('spin', {
    from { .transform(rotate3d(0, 0, 1, 0)); }
    to { .transform(rotate3d(0, 0, 1, 360deg)); }
});

.jobDetails {
    height: 100%;
    background-color: @white;
    padding:50px;
    width:50%;
    pointer-events:none;
    .translate3d(100%, 0, 0);
    .position(fixed, 0, 0, 0, auto, 10);
    .transition(all .5s ease);
    &.active {
        pointer-events:auto;
        .box-shadow(0 0 15px fadeout(@black, 50%));
        .translate3d(0, 0, 0);
    }
    .icon-arrow-left {
        color:@blue;
        cursor:pointer;
        font-size:32px;
    }
    .headWrapper {
        margin:0 0 30px;
        .display-flex;
        .align-items(flex-end);
        .flex-flow(row wrap);
        .justify-content(space-between);
        * {
            display:block;
        }
        b {
             margin:0 0 5px;
            .text-format(@circular, 20px, 24px, darken(@blue, 30%), 600);
        }
        span {
            width:100%;
            margin:0 0 5px;
            text-align:center;
            .text-format(@circular, 32px, 40px, @blue, 400);
        }
        em {
            .text-format(@circular, 14px, 24px, fadeout(@black, 50%), 400, normal);
        }
        label {
            text-align:right;
            margin:0;
            padding:0;
            border:0;
            .text-format(@circular, 18px, 24px, fadeout(@black, 50%), 400, normal);
        }
        strong {
            text-align:right;
            .text-format(@circular, 20px, 32px, @blue, 400, normal);
        }
    }
}
.backdrop {
    cursor:pointer;
    pointer-events:none;
    opacity:0;
    background-color:fadeout(darken(@blue, 25%), 50%);
    .transition(all .5s ease);
    .position(fixed, 0, 0, 0, 0, 9);
    &.active {
        opacity:1;
        pointer-events:auto;
    }
}

.careers {
    max-width:960px;
    article {
        margin:0 0 70px;
        opacity:0;
        .translate3d(0, 100px, 0);
        .transition(all .5s ease);
        .flex-center;
        .img, p {
            display:block;
            margin:0;
            padding:0 30px;
            .flex(1 0 auto);
        }
        .img {
            max-width:413px;
            padding:0 30px;
            height:160px;
            .flex-center;
            img {
                height:160px;
            }
        }
        p {
            font-size:24px;
            color:#444;
            max-width:480px;
            b {
                display:block;
                margin:0 0 15px;
                font-size:32px;
                color:@black;
                font-weight:400;
            }
        }
        &:nth-child(even) {
            .flex-flow(row-reverse nowrap);
        }
    }
    
    [data=active] {
        opacity:1;
        .translate3d(0, 0, 0);
    }
}

.careersWrapper {
    .lead {
        opacity:0;
        .translate3d(0, 100px, 0);
        .transition(all .5s ease);
        &[data=active] {
            opacity:1;
            .translate3d(0, 0, 0);
        }
    }
}

.pics {
    margin:50px auto 100px;
    .display-flex;
    .justify-content(center);
    .img {
        opacity:0;
        margin:0 -10% 0 0;
        position:relative;
        height:250px;
        max-width:300px;
        .flex-basis(0);
        .flex-grow(1);
        .transition(all .5s ease);
        &:hover {
            z-index:1;
        }
        img {
            overflow:hidden;
            display:block;
            width:100%;
            height:auto;
            border:10px solid @white;
            margin:-50% -33.4% 0 0;
            .transition(all .3s ease);
            .transform-origin(center center);
            .transform(rotate3d(0, 0, 1, 8deg));
            .box-shadow(0 3px 5px fadeout(@black, 90%));
            .position(absolute, 50%, 50%);
        }
        &:nth-child(2) {
            img {
                .transform(rotate3d(0, 0, 1, -4deg));
            }
        }
        &:nth-child(3) {
            img {
                .transform(rotate3d(0, 0, 1, 4deg));
            }
        }
        &:nth-child(4) {
            img {
                .transform(rotate3d(0, 0, 1, -5deg));
            }
        }
        &.active {
            img {
                width:90vw;
                height:auto;
                margin:-33.4vw -45vw 0 0;
                .transform(rotate3d(0, 0, 1, 0));
                .position(fixed, 50%, 50%, auto, auto, 1);
            }
        }
    }
    &[data=active] {
        .img {
            opacity:1;
            img {
                .transform(rotate3d(0, 0, 1, -8deg));
            }
            &:nth-child(2) {
                img {
                    .transform(rotate3d(0, 0, 1, 4deg));
                }
            }
            &:nth-child(3) {
                img {
                    .transform(rotate3d(0, 0, 1, -4deg));
                }
            }
            &:nth-child(4) {
                img {
                    .transform(rotate3d(0, 0, 1, 5deg));
                }
            }
            &.active {
                img {
                    width:90vw;
                    height:auto;
                    margin:-33.4vw -45vw 0 0;
                    .transform(rotate3d(0, 0, 1, 0));
                    .position(fixed, 50%, 50%, auto, auto, 1);
                }
            }
        }
    }
}

@media (max-width: @screen-md-max) {
	
}

@media (max-width: @screen-sm-max) {
	.jobDetails {
		width:80%;
	}
	.pics {
		.img {
			height:200px;
			max-width:200px;
			img {
				border:5px solid @white;
			}
		}
		&[data=active] {
			.img {
				&.active {
					z-index:2;
					img {
						position:absolute;
					}
					&:nth-child(1) {
						img {
							left:0;
							margin-left:-12vw;
						}
					}
					&:nth-child(2) {
						img {
							left:0;
							margin-left:-28vw;
						}
					}
					&:nth-child(3) {
						img {
							left:auto;
							right:0;
							margin-right:-19vw;
						}
					}
					&:nth-child(4) {
						img {
							left:auto;
							right:0;
							margin-right:-3vw;
						}
					}
				}
			}
		}
	}
}

@media (max-width: @screen-xs-max) {
	.jobs {
		li {
			&:not(.loader) {
				padding:20px 30px;
				display:block;
				label,
				strong {
					text-align:left;
				}
				label {
					margin-top:15px;
				}
				b {
					font-size:18px;
					line-height:24px;
				}
				span {
					font-size:20px;
					line-height:24px;
				}
				label {
					font-size:16px;
					line-height:20px;
				}
				strong {
					font-size:18px;
					line-height:22px;
				}
			}
		}
	}
	.careersWrapper {
		.filters {
			span {
				display:none;
			}
			select {
				font-size:14px;
			}
		}
	}
	.jobDetails {
		width:270px;
		padding:10px 30px 30px;
		.icon-arrow-left {
			display:block;
			margin:10px 0 20px;
			font-size:24px;
		}
		.headWrapper {
			span {
				text-align:left;
				font-size:22px;
				line-height:24px;
			}
			b {
				font-size:16px;
			}
			label,
			strong {
				display:inline;
				font-size:16px;
				margin-right:10px;
			}
		}
		p {
			font-size:12px;
			line-height:18px;
		}
	}
	.pics {
		display:block;
		.img {
			max-width:300px;
			margin:0 auto;
			height:80px;
			img {
				margin:-100px -100px 0 0;
				width:200px;
				height:auto;
			}
		}
		&[data=active] {
			.img {
				&.active {
					img {
						margin:0;
						width:300px;
						height:auto;
						left:50%;
						top:50%;
						margin:-100px -150px 0 0;
					}
					&:nth-child(1),
					&:nth-child(2),
					&:nth-child(3),
					&:nth-child(4) {
						img {
							margin:-100px 0 0 0;
						}
					}
				}
			}
		}
	}
	.careers {
		article {
			display:block;
			.img {
				height:auto;
				margin:0 auto 20px;
				img {
					width:100px;
					height:auto;
				}
			}
			p {
				max-width:none;
				padding:0 10px;
				text-align:center;
				b {
					font-size:24px;
				}
				span {
					font-size:18px;
					line-height:26px;
				}
			}
		}
	}
}
