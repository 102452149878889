.main {
	.globalTalent {
		&.active {
			img {
				&:last-child {
					.animation(blink .6s ease-in-out 4);
				}
			}
		}
	}
	.topDevelopers {
		.circle {
			width:342px;
			height:342px;
			margin:-171px -171px 0 0;
			.position(absolute, 50%, 50%);
			img {
				width:100px;
				margin:-10px -50px 0 0;
			}
		}
		img {
			&:nth-child(4) {
				opacity:0;
			}
		}
		&.active {
			.circle {
				.animation(search 1s linear forwards);
				img {
					.animation(searchInner 1s linear forwards);
				}
			}
			img {
				&:nth-child(3) {
					.animation(hideUser .5s ease 1s forwards);
				}
				&:nth-child(4) {
					.animation(showUser .5s ease 1s forwards);
				}
			}
		}
	}
	.intelligentTalent {
		img {
			.transition(all .5s ease);
			&:last-child {
				opacity:0;
			}
		}
		&.active {
			img {
				&:last-child {
					opacity:1;
				}
			}
		}
	}
	.quickOnboarding {
		img {
			&:nth-child(3),
			&:nth-child(4),
			&:nth-child(5),
			&:nth-child(6),
			&:nth-child(7) {
				opacity:0;
			}
		}
		&.active {
			img {
				&:nth-child(4) {
					.animation(showUser .5s ease forwards);
				}
				&:nth-child(5) {
					.animation(showUser .5s ease .5s forwards);
				}
				&:nth-child(6) {
					.animation(showUser .5s ease 1s forwards);
				}
				&:nth-child(7) {
					.animation(showUser .5s ease 1.5s forwards);
				}
				&:nth-child(2) {
					.animation(hideUser .5s ease 2s forwards);
				}
				&:nth-child(3) {
					.animation(showUser .5s ease 2s forwards);
				}
			}
		}
	}
	.teamManagement {
		img {
			&:nth-child(2),
			&:nth-child(3),
			&:nth-child(4) {
				opacity:0;
			}
			&:nth-child(2) {
				.translate3d(-100px, 0, 0);
			}
			&:nth-child(3),
			&:nth-child(4) {
				.translate3d(0, 50px, 0);
			}
		}
		&.active {
			img {
				&:nth-child(2) {
					.animation(translateIn .5s ease forwards);
				}
				&:nth-child(4) {
					.animation(translateIn .5s ease .5s forwards);
				}
				&:nth-child(3) {
					.animation(translateIn .5s ease 1s forwards);
				}
			}
		}
	}
}

.keyframes(search, {
		0%   { .transform(rotate(0deg)); }
		100% { .transform(rotate(360deg)); }
	}
);

.keyframes(searchInner, {
		0%   { .transform(rotate(0deg)); }
		100% { .transform(rotate(-360deg)); }
	}
);

.keyframes(hideUser, {
		0%   { opacity:1; }
		100% { opacity:0; }
	}
);

.keyframes(showUser, {
		0%   { opacity:0; }
		100% { opacity:1; }
	}
);

.keyframes(blink, {
		0%   { opacity:0; }
		100% { opacity:1; }
	}
);

.keyframes(translateIn, {
	0%   { opacity:0; }
	100% { opacity:1; .translate3d(0, 0, 0); }
}
);

