.wrapper404 {
	text-align:center;
	padding:15px;
	h4 {
		font-size:40px;
		line-height:40px;
		letter-spacing: -2px;
		margin:0;
		color:darken(@blue, 30%);
	}
	h2 {
		font-size:10vw;
		line-height: 10vw;
		letter-spacing: -2px;
		margin:0;
		color:@red;
	}
	p {
		font:300 italic 20px/30px @nunito;
		color:darken(@blue, 30%);
	}
	.btn {
		margin:0 auto;
		border:1px solid #eee;
		text-transform: uppercase;
		letter-spacing: 0;
		color:@blue;
		.flex-center;
	}
}