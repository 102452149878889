* {
	font-family:@circular;
	.box-sizing(border-box);
}

h1, h2, h3, h4, h5, h6 {
	font-family:@circular;
}

p {
	font-weight:300;
}

body {
    overflow-x:hidden;
    padding-top:154px;
}

header {
	padding:30px 0;
	color:@white;
	background-color:@blue;
	.position(fixed, 0, 0, auto, 0, 4);
	.container {
	    position:relative;
	    z-index:1;
	}
	nav {
		.display-flex;
		.align-items(center);
		.btn {
			height:48px;
			width:175px;
			font-size:16px;
		}
		a {
		    margin:0 auto;
		}
		.icon-logo,
		.icon-logo-upstack {
			font-size:54px;
			color:@white;
			display:block;
			margin:0 auto;
			padding-left:10px;
			.transition(all .3s ease);
		}
		> div {
			font-size:20px;
			line-height:20px;
			max-width:240px;
			.flex(1 0 240px);
			.display-flex;
			i {
			    position:relative;
			    top:2px;
			}
		}
		.phone {
		    white-space:nowrap;
			padding:0 10px 0 0;
			margin:0 10px 0 0;
			position:relative;
			span {
				.text-format(@circular, 18px, 24px, @white, 300, normal, normal, 2px);
			}
			i {
				margin:0 5px 0 0;
			}
		}
		.login {
		    a {
		        text-decoration:none;
		    }
			span {
				.text-format(@circular, 18px, 24px, @white, 300, normal, normal, 1px);
			}
			i {
				margin:0 0 0 5px;
			}
		}
		.btn-white {
			display:none;
		}
		.col-sm-3 {
			max-width:25%;
			.flex(1 0 25%);
		}
		.col-sm-6 {
			max-width:50%;
			.flex(1 0 50%);
		}
		.dropdown-wrapper {
		    width:400px;
		    padding:0 30px;
		    border:1px solid @white;
		    background-color:@white;
		    overflow:hidden;
		    max-height:0;
		    opacity:0;
		    .transition(all .3s ease);
		    .box-shadow(0 2px 3px fadeout(@black, 90%));
		    .display-flex;
            .position(absolute, 100%, auto, auto, -1px);
            ul {
                list-style:none;
                margin:0;
                padding:0;
                text-align:left;
                max-width:50%;
                .flex(1 0 50%);
                li {
                    &.title {
                        margin:0 0 20px;
                        .text-format(@circular, 12px, 20px, darken(@blue, 30%), 600, normal, uppercase, 1px);
                    }
                    a {
                        .transition(all .3s ease);
                        .text-format(@circular, 16px, 36px, fadeout(@black, 50%), 400);
                        &:hover {
                            font-weight:500;
                            color:@blue;
                        }
                    }
                }
            }
		}
		.dropdown {
		    border:1px solid fadeout(@white, 40%);
		    position:relative;
		    .border-radius(4px);
		    &:hover {
		        background-color:@white;
		        color:@blue;
		        border:1px solid transparent;
		        .dropdown-wrapper {
		            max-height:300px;
		            opacity:1;
		            padding:20px 30px;
		        }
		    }
		}
	}
	.mobile-menu {
        display:none;
	}
}

.leftMenu {
    display:none;
}

.fixed {
	padding-top:154px;
    header {
    	background-color:fadeout(@blue, 10%);
    	padding:15px 0;
    	.box-shadow(0 2px 2px fadeout(@black, 90%));
    	.position(fixed, 0, 0, auto, 0, 5);
    	nav {
    		.icon-logo,
		    .icon-logo-upstack {
    			font-size:42px;
    		}
    		.phone,
    		.login {
    			display:none;
    		}
    		.btn-white {
    			display:inline-block;
    			line-height:46px;
    		}
    	}
    }
}

.text-right {
	text-align:right;
	.justify-content(flex-end);
}

@media (max-width: @screen-md-max) {
    header {
        background-color:@blue;
    }
    .leftMenu {
        width:270px;
        background-color:@blue;
        display:block;
        .translate3d(-270px, 0, 0);
        .transition(all .3s ease);
        .position(fixed, 0, auto, 0, 0, 10);
        .icon-close {
            width:52px;
            height:52px;
            color:@white;
            font-size:24px;
            .flex-center;
            .position(absolute, 0, 0);
        }
        
    }
}

@media (max-width:@screen-xs-max) {
    body {
        padding-top:100px;
        .overlayer {
            display:block;
            width:100%;
            height:100%;
            pointer-events:none;
            background-color:fadeout(@black, 50%);
            opacity:0;
            .transition(all .3s ease);
            .position(fixed, 0, 0, 0, 0, 5);
        }
    }
    header {
        padding:0;
        .container {
            display:none;
        }
        .mobile-menu {
            position:relative;
            z-index:1;
            height:100px;
            .display-flex;
        }
    }
    .mobile-menu {
        .align-items(flex-start);
        .icon-menu {
            cursor:pointer;
            max-width:52px;
            height:52px;
            font-size:28px;
            padding:15px;
            .flex(1 0 52px);
            .flex-center;
        }
        .icon-logo,
        .icon-logo-upstack {
            font-size:40px;
            .position(relative, 20px);
            .flex(1 0 auto);
            .flex-center;
        }
        div {
            position:relative;
            max-width:52px;
            .flex(1 0 52px);
            .icon-phone,
            .icon-lock {
                cursor:pointer;
                width:40px;
                height:52px;
                font-size:24px;
                padding:15px;
                color:@white;
                .flex-center;
                position:absolute;
                top:0;
                right:0;
            }
            .icon-phone {
                right:40px;
            }
            a {
                color:@white;
            }
        }
    }
    .fixed {
        header {
            padding:0;
        }
        .mobile-menu {
            height:auto;
            .icon-logo,
            .icon-logo-upstack {
                top:0;
                font-size: 36px;
                line-height: 52px;
            }
        }
    }
    .menuActive {
        max-height:100%;
        overflow:hidden;
        .leftMenu {
            .translate3d(0, 0, 0);
        }
        .overlayer {
            pointer-events:auto;
            opacity:1;
        }
    }
    .fixed {
        padding:60px 0 0;
    }
}