.faqs {
    margin:100px auto 50px;
    .search {
        border:1px solid #ddd;
        height:70px;
        width:100%;
        .border-radius(35px);
        .display-flex;
        .align-items(flex-start);
    }
    input {
        border:0;
        background:0 0;
        display:block;
        height:68px;
        padding:0 50px;
        .placeholder(#999);
        .text-format(@circular, 20px, 30px, @black, 400);
        .flex(1 0 auto);
        &:focus {
            outline:none;
        }
    }
    .tabs {
        background-color:@white;
        cursor:pointer;
        list-style:none;
        margin:0;
        padding:0;
        min-width:300px;
        max-height:68px;
        overflow:hidden;
        padding-top:68px;
        position:relative;
        padding:68px 30px 0;
        border-left:1px solid #ddd;
        border-right:1px solid #ddd;
        margin-right:-1px;
        border-bottom:1px solid #ddd;
        .border-radius(0 35px 35px 0);
        .transition(all .3s ease);
        li {
            height:50px;
            line-height:50px;
            font-size:18px;
            color:#999;
            .transition(color .3s ease);
            &:hover {
                color:@black;
            }
            &.active {
                height:68px;
                line-height:68px;
                padding:0 30px;
                color:@black;
                .position(absolute, 0, 0, auto, 0);
                &:after {
                    content:'';
                    float:right;
                    display:block;
                    width:10px;
                    height:10px;
                    margin:25px 0 0;
                    border-bottom:1px solid #000;
                    border-right:1px solid #000;
                    .transition(all .3s ease);
                    .transform(rotate(45deg));
                }
            }
        }
        &.active {
            max-height:500px;
            .border-radius(0 35px 0 0);
            li.active {
                &:after {
                    margin:32px 0 0;
                    .transform(rotate(-135deg));
                }
            }
        }
    }
    .labels {
        margin:0;
        padding:0;
        list-style:none;
        .display-flex;
        .align-items(center);
        .justify-content(space-between);
        .flex-flow(row wrap);
        li {
            cursor:pointer;
            padding:0 18px;
            height:40px;
            margin:20px 10px 0 0;
            background-color:#f1f1f1;
            .border-radius(20px);
            .text-format(@circular, 16px, 40px, #999, 400);
            .flex-center;
            .transition(all .3s ease);
            i {
                font-size:24px;
                margin-right:10px;
            }
            &.active,
            &:hover {
                color:@white;
                background-color:@blue;
            }
            &:last-child {
                margin:20px 0 0;
            }
        }
    }
    h2 {
        margin:70px 0 10px;
        padding:0 50px 20px;
        border-bottom:1px solid #ddd;
        .text-format(@circular, 32px, 42px, darken(@blue, 30%), 600);
    }
}
.highlight {
    background-color:yellow;
}

.questions {
    list-style:none;
    margin:0;
    padding:0 50px;
    h2 {
        padding:0 0 20px;
    }
    h3 {
        margin:30px 0 10px;
        .text-format(@circular, 24px, 36px, @blue, 400);
    }
    p {
        margin:0;
        .text-format(@circular, 17px, 28px, @black, 400);
    }
    ol {
        span {
            .text-format(@circular, 17px, 28px, @black, 400);
        }
    }
    
}

@media (max-width: @screen-md-max) {
	
}

@media (max-width: @screen-sm-max) {
	
}

@media (max-width: @screen-xs-max) {
	.faqs {
		margin:50px 0 25px;
		input {
			height:48px;
			padding:0 25px;
			font-size:18px;
		}
		.search {
			height:50px;
			.border-radius(25px);
		}
		.tabs {
			display:none;
		}
		.labels {
			display:none;
		}
		.questions {
			padding:0;
		}
		h2 {
			margin:40px 0 0;
			font-size:24px;
			line-height:32px;
		}
		h3 {
			font-size:20px;
			line-height:30px;
			margin:30px 0 10px;
		}
	}
}
