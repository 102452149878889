.clients {
    article {
        margin:0 0 100px;
        .display-flex;
        > div {
            padding: 0 25px;
            max-width:50%;
            .flex(1 0 50%);
            img {
                display:block;
                &[src*='mealpal.jpg'] {
                    max-width:200px;
                }
            }
            > div {
                &:before {
                    content:'';
                    display:block;
                    float:left;
                    padding-bottom:56%;
                }
                .preview {
                    position:relative;
                }
                img,
                iframe {
                    display:none;
                    width:100%;
                    height:auto;
                    margin:0 0 20px;
                }
                img {
                    display:block;
                    &.play {
                        cursor:pointer;
                        width:70px;
                        height:auto;
                        margin:-25px -35px 0 0;
                        .transform-origin(center center);
                        .transition(all .3s ease);
                        .border-radius(12px);
                        .box-shadow(0 0 15px fadeout(@white, 50%));
                        .position(absolute, 50%, 50%);
                        &:hover {
                            .transform(scale3d(1.2, 1.2, 1.2));
                        }
                    }
                }
                &.active {
                    .display-flex;
                    .preview { display:none }
                    iframe { display:block; }
                }
            }
        }
        b,
        span {
            display:block;
            text-align:center;
            margin:0 0 5px;
        }
        b {
            font-size:18px;
            color:darken(@blue, 30%);
        }
        span {
            font-size:16px;
            color:#444;
        }
        h3 {
            font-weight:400;
            font-size:30px;
            color:darken(@blue, 30%);
        }
        p {
            font-size:19px;
            line-height:30px;
            color:#444;
        }
        &:nth-child(even) {
            .flex-flow(row-reverse nowrap);
        }
    }

    h2, article > div {
        opacity:0;
        .translate3d(0, 200px, 0);
        .transition(all .5s ease);
        &[data=active] {
            opacity:1;
            .translate3d(0, 0, 0);
        }
    }
}

@media (max-width: @screen-md-max) {
	
}

@media (max-width: @screen-sm-max) {
	.clients {
		article {
			margin:0 0 50px;
			.flex-flow(row wrap);
			> div {
				max-width:none;
				.flex(1 0 100%);
			}
			&:nth-child(even) {
				.flex-flow(row wrap);
			}
			h3 {
				font-size:24px;
				line-height:30px;
			}
			p {
				font-size:16px;
				line-height:24px;
			}
			b {
				font-size:16px;
			}
			span {
				font-size:14px;
			}
		}
	}
}

@media (max-width: @screen-xs-max) {
	.clients {
		article {
			> div {
				padding:0 10px;
				> img {
					max-width:120px;
					&[src*='mealpal.jpg'] {
						max-width:120px;
					}
				}
			}
		}
	}
}
